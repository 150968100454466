import { formatISO } from 'date-fns';
import { mergeDateAndTime } from '../helpers';
import { SearchFormInterface, SearchRatesDTO } from '../interfaces/search-form.interfaces';
import { environment } from '../../../environments/environment';

export const searchFormToDTO = (form: SearchFormInterface): SearchRatesDTO => ({
  pickup_datetime: formatISO(mergeDateAndTime(form.pickUpDate, form.pickUpTime)),
  return_datetime: formatISO(mergeDateAndTime(form.dropOffDate, form.dropOffTime)),
  pickup_desk_code: form.pickUpLocation.code,
  return_desk_code: form.dropOffLocation.code,
  driver_age: form.driversAge,
  country: form.nationality ? form.nationality : 'PT',
  contract_code: environment.contract
});

