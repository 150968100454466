<div class="confirmation" *ngIf="car$ | async as car">

  <div class="heading">
    Your booking details
  </div>

  <div class="card">

    <div class="car-info">

      <div class="car-wrapper">
        <img class="car-image" [src]="this.car.vehicle.image" [alt]="car.vehicle.make_model" imgPlaceholder>

        <div class="car-descr">
          <div class="car-name">{{ car.vehicle.make_model }} {{ car.vehicle.transmission_type }}</div>
<!--          <ng-container *ngIf="includedInPrice$ | async as includedInPrice">-->
<!--            <ng-container *ngIf="includedInPrice.length">-->
<!--              <div class="car-included">Included in price:</div>-->

<!--              <div class="car-additional">-->
<!--                <div class="car-additional-block">-->
<!--                  <ng-container *ngFor="let item of includedInPrice; let i = index">-->
<!--                    <p *ngIf="i % 2 === 0">-->
<!--                      {{ item }}-->
<!--                    </p>-->
<!--                  </ng-container>-->
<!--                </div>-->
<!--                <div class="car-additional-block">-->
<!--                  <ng-container *ngFor="let item of includedInPrice; let i = index">-->
<!--                    <p *ngIf="i % 2 !== 0">-->
<!--                      {{ item }}-->
<!--                    </p>-->
<!--                  </ng-container>-->
<!--                </div>-->
<!--              </div>-->
<!--            </ng-container>-->
<!--          </ng-container>-->
        </div>
      </div>

      <car-icon-list [alignCenter]="true" [car]="car$ | async"></car-icon-list>

    </div>

    <div class="additional">

      <div class="options" [formGroup]="additionalOptionsForm">
        <div class="section-title">
          Additional options
        </div>

        <ng-container *ngFor="let item of car.equipment">
          <div class="option">
            <div class="option-name">{{ item.description }}</div>
            <car-count-control
              [amount]="+item.charge.amount + +item.charge.tax_amount.total"
              [formControlName]="countControlName(item)"
            ></car-count-control>
          </div>
        </ng-container>

      </div>

      <div class="vertical"></div>

      <div class="summary">
        <div class="section-title">
          Price summary
        </div>

        <div class="price-list">
          <div class="price-list-item">

            <ng-container>
              <div class="price-item-name">VEHICLE RENT {{ bookingDays$ | async }} {{ (bookingDays$ | async)! > 1 ? 'days' : 'day' | uppercase }}</div>
            </ng-container>

            <div *ngFor="let item of appliedEquipment" class="price-item-name">{{ item.name }}</div>
          </div>

          <div class="vertical"></div>

          <div class="price-list-item">
            <div class="price-item">
              {{ carCalculationsService.getVehicleRentPriceWithFee((bookingDays$ | async)!) | number: '1.2-2' }}
            </div>
            <div *ngFor="let item of appliedEquipment" class="price-item">
              {{ carCalculationsService.withBookingFee(item.price * (bookingDays$ | async)!) | number: '1.2-2' }}
            </div>
          </div>
        </div>

        <div class="total">
          <div class="section-title total-title">
            Total price
          </div>

          <div class="total-quantity">
            {{ carCalculationsService.getTotalPriceWithFee((equipmentTotal$ | async)!, (bookingDays$ | async)!) | number: '1.2-2' }} EUR
          </div>
        </div>
      </div>

    </div>

    <div class="personal full-section" [formGroup]="personalForm">
      <div class="section-title full">Personal info</div>

      <div class="form-row">
        <div class="form-group full-width">
          <p class="title dark required">First name</p>
          <nb-form-field>
            <input
              formControlName="first_name"
              fullWidth
              shape="round"
              size="tiny"
              nbInput
              placeholder="Johny"
            >
          </nb-form-field>
          <car-error-message [control]="personalForm.controls['first_name']"></car-error-message>
        </div>
        <div class="form-group full-width">
          <p class="title dark required">Last name</p>
          <nb-form-field>
            <input
              formControlName="last_name"
              fullWidth
              shape="round"
              size="tiny"
              nbInput
              placeholder="Depp"
            >
          </nb-form-field>
          <car-error-message [control]="personalForm.controls['last_name']"></car-error-message>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group full-width">
          <p class="title dark required">Email</p>
          <nb-form-field>
            <input
              formControlName="email"
              fullWidth
              type="email"
              shape="round"
              size="tiny"
              nbInput
              placeholder="email@example.com"
            >
          </nb-form-field>
          <car-error-message [control]="personalForm.controls['email']"></car-error-message>
        </div>
        <div class="form-row-half">
          <div class="form-group full-width">
            <p class="title dark required">Telephone</p>
            <nb-form-field>
              <input
                prefix="+ "
                mask="0*"
                [dropSpecialCharacters]="false"
                type="tel"
                formControlName="phone_number"
                fullWidth
                shape="round"
                size="tiny"
                nbInput
                placeholder="+00000000000"
              >
            </nb-form-field>
            <car-error-message [control]="personalForm.controls['phone_number']"></car-error-message>
          </div>
          <div class="form-group full-width">
            <p class="title dark">Flight nr.</p>
            <nb-form-field>
              <input
                formControlName="flight_number"
                fullWidth
                shape="round"
                size="tiny"
                nbInput
                placeholder="123456"
              >
            </nb-form-field>
            <car-error-message [control]="personalForm.controls['flight_number']"></car-error-message>
          </div>
        </div>
      </div>
    </div>

    <div class="payment full-section">
      <div class="section-title full">payment information</div>

      <div class="pricing">

        <div class="pricing-block">

          <div class="left">
            <div class="price-item">BOOKING FEE</div>
            <div class="price-item">DESK PAYMENT</div>
          </div>
          <div class="right">
            <div class="price-item">{{ carCalculationsService.getTotalFee((equipmentTotal$ | async)!, (bookingDays$ | async)!) | number: '1.2-2' }}</div>
            <div class="price-item">{{ carCalculationsService.getPaymentOnDesk((equipmentTotal$ | async)!, (bookingDays$ | async)!) | number: '1.2-2' }}</div>
          </div>

        </div>
        <div class="horizontal"></div>
        <div class="pricing-block">
          <div class="left">
            <div class="price-item">TOTAL PAYMENT</div>
          </div>
          <div class="right">
            <div class="price-item">{{ carCalculationsService.getTotalPriceWithFee((equipmentTotal$ | async)!, (bookingDays$ | async)!) | number: '1.2-2' }}</div>
          </div>
        </div>

      </div>

    </div>

    <div class="terms">
      <nb-checkbox [(ngModel)]="agreedWithTerms">
      </nb-checkbox>
      <span class="terms-text" (click)="openTermsConditions()">Agree with terms</span>
    </div>

    <button
     [disabled]="disableButton || !!(reservationPending$ | async)"
     (click)="makeReservation()"
     nbButton
     class="book-btn"
     [nbSpinner]="!!(reservationPending$ | async)"
    >
     BOOK THIS CAR
    </button>

  </div>

</div>
