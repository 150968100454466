<div class="booking-body" *ngIf="!(ratesPending$ | async); else loader">

  <div class="filter">

    <div class="container">
      <div class="filter-title">Filter by:</div>

      <div class="options" [formGroup]="filtersForm">
        <div class="all" (click)="resetFilters()">All types</div>

        <nb-checkbox formControlName="mini">Mini</nb-checkbox>
        <nb-checkbox formControlName="allWheelDrive">4WD/AWD</nb-checkbox>
        <nb-checkbox formControlName="sixSeats">6+ seats</nb-checkbox>
        <nb-checkbox formControlName="automatic">Automatic</nb-checkbox>
      </div>
    </div>

    <div class="container">
      <div class="filter-title">Price</div>

      <div class="sort">
        <button
          nbButton
          size="small"
          shape="round"
          [status]="(sorting$ | async) === Sorting.ASC ? 'primary' : 'basic'"
          (click)="changePriceSortingTo(Sorting.ASC)"
        >
          <nb-icon icon="arrow-ios-upward-outline"></nb-icon>
        </button>
        <button
          nbButton
          size="small"
          shape="round"
          [status]="(sorting$ | async) === Sorting.DESC ? 'primary' : 'basic'"
          (click)="changePriceSortingTo(Sorting.DESC)"
        >
          <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
        </button>
      </div>
    </div>


  </div>

  <ng-container *ngIf="(rates$ | async)?.length; else notFound">


    <div class="cars">

      <ng-container *ngFor="let vehicle of (rates$ | async)">
        <car-info-card [car]="vehicle"></car-info-card>
      </ng-container>

    </div>
  </ng-container>

</div>

<ng-template #notFound>
  <div class="not-found">
    <nb-icon icon="alert-triangle-outline" pack="eva"></nb-icon>
    Not found any car for your search request!
  </div>
</ng-template>

<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loader" [nbSpinner]="true" nbSpinnerSize="giant"></div>
  </div>
</ng-template>
