import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { NbButtonModule, NbCheckboxModule, NbIconModule, NbSpinnerModule } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, filter, map, take } from 'rxjs';
import { checkACRISS } from 'src/app/core/helpers';
import { DeskInterface } from 'src/app/core/interfaces/desks.interfaces';
import { AvailableVehicleInterface, TransmissionType } from 'src/app/core/interfaces/rates.interfaces';
import { DesksDataService } from '../../core/services/desks-data.service';
import { FleetsDataService } from '../../core/services/fleets-data.service';
import { RatesDataService } from '../../core/services/rates-data.service';
import { SearchResultsDataService } from '../../core/services/search-results-data.service';
import { InfoCardComponent } from './info-card/info-card.component';

export enum Sorting {
  DESC = 'desc',
  ASC = 'asc'
}

export interface FiltersInterface {
  mini: boolean,
  automatic: boolean,
  sixSeats: boolean,
  allWheelDrive: boolean
}

@UntilDestroy()
@Component({
  selector: 'car-booking-search-list',
  templateUrl: './booking-search-list.component.html',
  styleUrls: ['./booking-search-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NbCheckboxModule,
    NbButtonModule,
    NbIconModule,
    InfoCardComponent,
    NbSpinnerModule,
  ],
})
export class BookingSearchListComponent {
  Sorting = Sorting;

  filtersForm = this.fb.group({
    mini: [false],
    automatic: [false],
    sixSeats: [false],
    allWheelDrive: [false],
  });

  filters$ = new BehaviorSubject<FiltersInterface>({
    allWheelDrive: false,
    automatic: false,
    mini: false,
    sixSeats: false,
  });
  sorting$ = new BehaviorSubject<Sorting>(Sorting.ASC);
  rates$ = combineLatest([
    this.ratesDataService.rates$,
    this.filters$,
    this.sorting$,
  ]).pipe(
    map(([rates, filters, sorting]) => this.filterAndSortRates(rates, filters, sorting)),
    untilDestroyed(this),
  );
  ratesPending$ = this.ratesDataService.isLoading$;

  constructor(
    private fb: UntypedFormBuilder,
    private searchResultDataService: SearchResultsDataService,
    private desksDataService: DesksDataService,
    private fleetsDataService: FleetsDataService,
    private ratesDataService: RatesDataService
  ) {
    this.searchResultDataService.clearSelectedCar();

    this.fleetsDataService.loadFleets();

    this.desksDataService.desks$
      .pipe(
        filter((res: DeskInterface[]) => Boolean(!res || !res.length)),
        take(1),
      )
      .subscribe(() => this.desksDataService.loadDesks());

    this.filtersForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.filters$.next(this.filtersForm.getRawValue() as FiltersInterface));
  }

  changePriceSortingTo(sorting: Sorting): void {
    this.sorting$.next(sorting);
  }

  resetFilters(): void {
    this.filtersForm.reset();
  }

  private filterAndSortRates(
    rates: AvailableVehicleInterface[],
    filters: FiltersInterface,
    sorting: Sorting,
  ): AvailableVehicleInterface[] {
    return rates
      .filter((car: AvailableVehicleInterface) => filters.automatic ? car.vehicle.transmission_type === TransmissionType.Automatic : true)
      .filter((car: AvailableVehicleInterface) => filters.sixSeats ? car.vehicle.passenger_quantity >= 6 : true)
      .filter((car: AvailableVehicleInterface) => filters.allWheelDrive ? checkACRISS(car.vehicle.code, 2, ['c', 'n', 'b', 'd']) : true)
      .filter((car: AvailableVehicleInterface) => filters.mini ? checkACRISS(car.vehicle.code, 0, ['m', 'n']) : true)
      .sort((a, b) =>
      {
        const amountA = parseFloat(a.rental_rate.vehicle_charges.find((e) => e.ota_code === 1)?.amount ?? '0');
        const amountB = parseFloat(b.rental_rate.vehicle_charges.find((e) => e.ota_code === 1)?.amount ?? '0');

        return sorting === Sorting.ASC ? amountA - amountB : amountB - amountA;
      }
      );
  }

}
