<div class="header" [ngStyle]="{ alignItems: isHomePage ? 'flex-start' : 'center' }">

  <img
    class="logo"
    (click)="resetForm()"
    routerLink="/"
    [class.logo-wide]="!isHomePage"
    [ngSrc]="isHomePage ? 'assets/images/logo-bottom.svg' : 'assets/images/logo-right.svg'"
    [width]="isHomePage ? 123 : 195"
    [height]="isHomePage ? 89 : 45 "
    alt="Car-Bro">

  <div class="nav">

<!--    <div class="link">-->
<!--      <a>WHO WE ARE?</a>-->
<!--    </div>-->

    <div class="link" (click)="openContactUs()">
      CONTACT US
    </div>


<!--    <nb-form-field class="select">-->
<!--      <nb-select selected="eur" fullWidth shape="round" size="small">-->
<!--        <nb-option [value]="'eur'">EUR</nb-option>-->
<!--        <nb-option [value]="'usd'">USD</nb-option>-->
<!--        <nb-option [value]="'gbp'">GBP</nb-option>-->
<!--      </nb-select>-->
<!--    </nb-form-field>-->

<!--    <nb-form-field class="select">-->
<!--      <nb-select selected="eng" fullWidth shape="round" size="small">-->
<!--        <nb-option [value]="'eng'">ENG</nb-option>-->
<!--        <nb-option [value]="'it'">IT</nb-option>-->
<!--      </nb-select>-->
<!--    </nb-form-field>-->

  </div>

</div>
