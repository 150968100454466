<div class="car-card">

  <div class="images">
    <img class="image"
         [src]="car.vehicle.image"
         [alt]="car.vehicle.make_model"
         imgPlaceholder
         placeholderLink="assets/images/car-placeholder.jpg"
    >

    <img class="image fleet"
         [src]="fleetLogoUrl$ | async"
         imgPlaceholder
         alt="Fleet Logo"
    >
  </div>

  <div class="description">

    <div class="name">{{ car.vehicle.make_model }} {{ car.vehicle.transmission_type }}</div>

    <car-icon-list [car]="car"></car-icon-list>

    <div class="included" *ngIf="includedInPrice.length">
      Included in price
    </div>

    <div class="characteristics" *ngIf="includedInPrice.length">

      <ng-container>

        <div class="left">
          <ng-container *ngFor="let item of includedInPrice; let i = index">
            <p *ngIf="i % 2 === 0">
              {{ item }}
            </p>
          </ng-container>
        </div>

        <div class="right">
          <ng-container *ngFor="let item of includedInPrice; let i = index">
            <p *ngIf="i % 2 !== 0">
              {{ item }}
            </p>
          </ng-container>
        </div>

      </ng-container>

    </div>

  </div>

  <div class="price">

    <div class="border">
      <div class="amount">{{ carCalculationsService.getVehicleRentPriceWithFee((bookingDays$ | async)!) | number:'1.2-2' }} EUR</div>

      <div class="descr">
        <p>for {{ bookingDays$ | async }} {{ (bookingDays$ | async)! > 1 ? 'days' : 'day' }}
          - {{ carCalculationsService.getPricePerDay() | number:'1.2-2' }} EUR per day</p>
        <p>VAT included in price</p>
      </div>
    </div>

    <button (click)="bookCar()" nbButton size="giant" fullWidth shape="round">Book this car</button>

  </div>

</div>
