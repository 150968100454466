import { CommonModule, ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import {
  NbAutocompleteModule, NbButtonModule,
  NbDatepickerComponent, NbDatepickerModule, NbFormFieldModule,
  NbIconModule, NbInputModule,
  NbStepChangeEvent,
  NbStepperComponent, NbStepperModule,
  NbTimepickerModule,
} from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addDays, isAfter, isEqual, sub } from 'date-fns';
import { NgxMaskDirective } from 'ngx-mask';
import { filter, map, Observable, switchMap, take } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AgeFormatDirective } from '../core/directives/age-format.directive';
import { DesksDataService } from '../core/services/desks-data.service';
import { RatesDataService } from '../core/services/rates-data.service';
import { SearchResultsDataService } from '../core/services/search-results-data.service';
import {
  datesAfterToday,
  getAgeMask,
  isWrongInterval,
  handeDeskView,
  mergeDateAndTime, fuzzySearch,
} from '../core/helpers';
import { DeskInterface } from '../core/interfaces/desks.interfaces';
import { SearchFormInterface } from '../core/interfaces/search-form.interfaces';
import { searchFormToDTO } from '../core/mappers/rates.mappers';
import { ErrorMessageComponent } from '../core/components/error-message/error-message.component';
import { HeadBlockComponent } from '../layout/head-block/head-block.component';
import { INIT_DATE_TIME } from '../constants';


@UntilDestroy()
@Component({
  selector: 'car-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NbIconModule,
    ErrorMessageComponent,
    NbTimepickerModule,
    NbFormFieldModule,
    NbDatepickerModule,
    NbAutocompleteModule,
    NbStepperModule,
    HeadBlockComponent,
    NgxMaskDirective,
    NbInputModule,
    ReactiveFormsModule,
    NbButtonModule,
    AgeFormatDirective
  ],
})
export class BookingComponent {
  @ViewChild('stepper') stepper!: NbStepperComponent;
  @ViewChild('dropOffDate') dropOffDate!: NbDatepickerComponent<Date>;

  desks$ = this.desksDataService.desks$;
  ratesIsLoading$ = this.ratesDataService.isLoading$;
  filteredDropOff$!: Observable<DeskInterface[]>;
  filteredPickUp$!: Observable<DeskInterface[]>;

  form = this.fb.group({
    pickUpLocation: new UntypedFormControl(null, Validators.required),
    pickUpDate: new UntypedFormControl(null, Validators.required),
    pickUpTime: new UntypedFormControl(null, Validators.required),
    dropOffLocation: new UntypedFormControl(null, Validators.required),
    dropOffDate: new UntypedFormControl(null, Validators.required),
    dropOffTime: new UntypedFormControl(null, Validators.required),
    driversAge: new UntypedFormControl(null, [Validators.required, Validators.min(18), Validators.max(99)]),
    nationality: [null],
    promocode: [null],
  });

  stepperURL = ['/booking', '/booking/confirmation', '/booking/voucher'];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
    private searchResultDataService: SearchResultsDataService,
    private desksDataService: DesksDataService,
    private ratesDataService: RatesDataService,
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => {
      this.patchForm();
      this.viewportScroller.scrollToPosition([0, 0]);
      this.cdr.detectChanges();
    });
    this.form.controls['pickUpDate'].valueChanges
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
      )
      .subscribe((value: Date) => {
        this.form.controls['dropOffDate'].setValue(addDays(value, 3));
      });
    this.filteredPickUp$ = this.getFilteredDesks(this.form.controls['pickUpLocation']);
    this.filteredDropOff$ = this.getFilteredDesks(this.form.controls['dropOffLocation']);
  }

  ngAfterViewInit(): void {
    // for correct min date before select date in pickUpDate
    if (this.dropOffDate) {
      const form = this.form.getRawValue();
      if (form.pickUpDate && isEqual(form.pickUpDate, INIT_DATE_TIME.pickUpDate)) {
        this.dropOffDate.min = sub(new Date(form.pickUpDate), { days: 1 });
        this.dropOffDate.visibleDate = sub(new Date(form.pickUpDate), { days: 1 });
        this.cdr.detectChanges();
      }
    }
  }

  handeDeskView = handeDeskView;
  datesAfterToday = datesAfterToday;

  get isSearchList(): boolean {
    return this.router.url === this.stepperURL[0];
  }

  get currentStep(): number {
    return this.stepperURL.findIndex((item) => item === this.router.url.split('?')[0]);
  }

  get datesIntervalError(): boolean {
    const { pickUpDate, pickUpTime, dropOffDate, dropOffTime } = this.form.getRawValue();
    return isWrongInterval(pickUpDate, pickUpTime, dropOffDate, dropOffTime);
  }

  get inPastError(): boolean {
    const pickUpDate = this.form.controls['pickUpDate'];
    const pickUpTime = this.form.controls['pickUpTime'];

    if (this.form.untouched) return false;

    const date = mergeDateAndTime(new Date(pickUpDate.value || ''), new Date(pickUpTime.value || ''));
    return isAfter(new Date(), date);
  }

  get ageMask(): string {
    return getAgeMask(this.form.controls['driversAge']?.value);
  }

  stepChanged(stepEvent: NbStepChangeEvent): void {
    this.router.navigate([this.stepperURL[stepEvent.index]]).then(() => {
      this.stepper.selectedIndex = this.currentStep;
    });
    stepEvent.previouslySelectedStep.interacted = false;
  }

  setControlTouched(control: AbstractControl): void {
    control.markAsTouched();
  }

  search(): void {
    const form = this.form.getRawValue();

    this.searchResultDataService.saveSearchForm(form);
    this.ratesDataService.loadRates(searchFormToDTO(form));
  }

  private getFilteredDesks(control: AbstractControl): Observable<DeskInterface[]> {
    return control.valueChanges.pipe(
      startWith(''),
      switchMap((value) =>
        this.desks$.pipe(
          map((desks: DeskInterface[]) => desks.filter((desk: DeskInterface) => Boolean(desk.active))),
          map((desks: DeskInterface[]) => fuzzySearch(desks, ['name', 'city', 'state'], value)),
        ),
      ),
      untilDestroyed(this),
    );
  }

  private patchForm(): void {
    this.searchResultDataService.searchForm$
      .pipe(take(1))
      .subscribe((form: SearchFormInterface | null) => {
        if (form) {
          this.form.patchValue(form);
        } else {
          this.form.patchValue({
            pickUpDate: INIT_DATE_TIME.pickUpDate,
            pickUpTime: INIT_DATE_TIME.pickUpTime,
            dropOffTime: INIT_DATE_TIME.dropOffTime,
            driversAge: INIT_DATE_TIME.driversAge,
          });
        }
      });
  }

}
