import {Injectable} from '@angular/core';
import {AvailableVehicleInterface} from 'src/app/core/interfaces/rates.interfaces';
import {ReservationInterface} from 'src/app/core/interfaces/reservations.interfaces';

@Injectable({
  providedIn: 'any',
})
export class CarCalculationsService {
  _selectedCar!: ReservationInterface | AvailableVehicleInterface;

  set selectedCar(car: ReservationInterface | AvailableVehicleInterface) {
    this._selectedCar = car;
  }

  get selectedCar() {
    return this._selectedCar;
  }

  withBookingFee(amount: number): number {
    return +amount + (+amount * this.getBookingFeePercentage());
  }

  getBookingFeePercentage(): number {
    return +this.selectedCar.rate_adjustment / 100;
  }

  getVehicleRentPriceWithFee(bookingDays: number): number {
    return this.withBookingFee(+(this.selectedCar.rental_rate.vehicle_charges.find((e) => e.ota_code === 1)?.amount ?? 0) * bookingDays);
  }

  getPricePerDay(): number {
    return this.withBookingFee(+(this.selectedCar.rental_rate.vehicle_charges.find((e) => e.ota_code === 1)?.amount ?? 0));
  }

  getTotalPriceWithFee(equipmentPrice: number, bookingDays: number): number {
    return this.withBookingFee(this.getPaymentOnDesk(equipmentPrice, bookingDays));
  }

  getPaymentOnDesk(equipmentPrice: number, bookingDays: number): number {
    return equipmentPrice + +(this.selectedCar.rental_rate.vehicle_charges.find((e) => e.ota_code === 1)?.amount ?? 0) * bookingDays;
  }

  getTotalFee(equipmentPrice: number, bookingDays: number): number {
    return this.getPaymentOnDesk(equipmentPrice, bookingDays) * this.getBookingFeePercentage();
  }
}
