import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RatesInterface } from '../interfaces/rates.interfaces';
import { SearchRatesDTO } from '../interfaces/search-form.interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RatesApiService {

  constructor(private http: HttpClient) {}

  ratesURL = `${environment.apiUrl}/rates`;

  getRates(search: SearchRatesDTO): Observable<RatesInterface> {
    return this.http.post<RatesInterface>(this.ratesURL, search, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
  }

  getRate(quotation: String): Observable<RatesInterface> {
    return this.http.get<RatesInterface>(this.ratesURL + '/' + quotation);
  }


}
