import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NbIconModule } from '@nebular/theme';
import { checkACRISS } from '../../../core/helpers';
import { AvailableVehicleInterface } from '../../../core/interfaces/rates.interfaces';

@Component({
  selector: 'car-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NbIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconListComponent {
  @Input() car!: AvailableVehicleInterface | null;
  @Input() alignCenter = false;

  withAirConditioner(): boolean {
    if (this.car) {
      return checkACRISS(this.car.vehicle.code, 3, ['r', 'd', 'h', 'i', 'e', 'c', 'l', 'a', 'm', 'v', 'u']);
    }
    return false;
  }

  isAWD(): boolean {
    if (this.car) {
      return checkACRISS(this.car.vehicle.code, 2, ['n', 'c', 'b', 'd']);
    }
    return false;
  }

}
