import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbButtonModule, NbIconModule } from '@nebular/theme';

export interface CountControlValueInterface {
  quantity: number;
  price: number;
}

@Component({
  selector: 'car-count-control',
  template: `
    <div class="count-ctrl">
      <button nbButton
              size="small"
              shape="round"
              (click)="onRemove()"
              [disabled]="disabled || quantity <= min"
              status="primary">
        <nb-icon icon="minus" pack="eva"></nb-icon>
      </button>
      <div class="quantity">{{ quantity }}</div>
      <button nbButton
              size="small"
              shape="round"
              (click)="onAdd()"
              [disabled]="disabled || quantity >= max"
              status="primary">
        <nb-icon icon="plus" pack="eva"></nb-icon>
      </button>
    </div>
  `,
  styles: [
    `.count-ctrl {
      display: flex;
      align-items: center;
      gap: 1rem
    }`,
    `.quantity {
      font-size: 1.75rem;
      color: var(--color-primary-blue);
      font-weight: 500;
      width: 1.25rem;
      text-align: center;
    }`,
  ],
  standalone: true,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CountControlComponent }],
  imports: [
    CommonModule,
    NbIconModule,
    NbButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountControlComponent implements ControlValueAccessor {
  @Input() min = 0;
  @Input() max = 4;
  @Input() amount = 1;

  price = 0;
  quantity = 0;
  onChange = (data: CountControlValueInterface) => {};
  onTouched = () => {};

  touched = false;

  disabled = false;

  onAdd() {
    if (!this.disabled) {
      this.markAsTouched();
      this.quantity += 1;
      this.price = this.quantity * this.amount;
      this.onChange({ quantity: this.quantity, price: this.price });
    }
  }

  onRemove() {
    if (!this.disabled) {
      this.markAsTouched();
      this.quantity -= 1;
      this.price = this.quantity * this.amount;
      this.onChange({ quantity: this.quantity, price: this.price });
    }
  }

  writeValue(data: CountControlValueInterface) {
    this.price = data.price;
    this.quantity = data.quantity;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
