<div class="head" [class.home]="isHomePage">
  <div class="line orange"></div>

  <div class="content">
    <div class="wrapper">
      <car-header [isHomePage]="isHomePage"></car-header>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="line blue"></div>
</div>
