import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeskInterface } from '../interfaces/desks.interfaces';
import { Pageable } from '../interfaces/pageable.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DesksApiService {
  constructor(private http: HttpClient) {}

  desksURL = `${environment.apiUrl}/desks`;

  getDesks(): Observable<Pageable<DeskInterface>> {
    return this.http.get<Pageable<DeskInterface>>(this.desksURL);
  }

}
