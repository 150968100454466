import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[imgPlaceholder]',
  standalone: true
})
export class ImgPlaceholderDirective {
  @Input() placeholderLink = '';

  @HostListener('error', ['$event'])
  onError(): void {
    this.el.nativeElement.src = this.placeholderLink || 'https://via.placeholder.com/300?text=Image+Not+Found';
  }

  constructor(private el: ElementRef) {}

}
