import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NbButtonModule, NbDialogRef, NbFormFieldModule, NbInputModule } from '@nebular/theme';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { onlyLettersValidator } from '../../core/validators';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { ErrorMessageComponent } from '../../core/components/error-message/error-message.component';

@Component({
  selector: 'car-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogHeaderComponent,
    NbFormFieldModule,
    ErrorMessageComponent,
    NbButtonModule,
    NbInputModule,
  ],
})
export class ContactUsDialogComponent {

  form = this.fb.group({
    name: ['', [Validators.required, onlyLettersValidator()]],
    // email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    message: ['', [Validators.required]],
  });

  constructor(
    protected dialogRef: NbDialogRef<ContactUsDialogComponent>,
    private fb: UntypedFormBuilder,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  openEmailClient(): void {
    const mail = document.createElement("a");
    const form = this.form.getRawValue();
    mail.href = `mailto:bookings@car-bro.com?subject=Question from ${form.name} to Car-Bro&body=${form.message}`;
    mail.click();
  }
}
