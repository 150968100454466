<div class="terms">
  <car-dialog-header (close)="close()" [title]="'Terms & Conditions'"></car-dialog-header>

  <div class="scrollable">
    <div class="text" #terms>
      <p class="heading">CAR-BRO.com TERM AND CONDITIONS</p>
      <div>
        The rental conditions are determined by each rental company and CAR-BRO Ltd has no influence on
        the conditions applied to each contract.
      </div>

      <p class="heading">INTERNATIONAL GENERAL CONDITIONS</p>
      <div>
        It is obligatory to present your rental voucher on collection of the car.
      </div>
      <div>
        You need to present the following things on collection of the car: your passport or National Identity
        Card, driving licence, a valid credit card in the name of the main driver.
      </div>

      <p class="heading-2">Age Requirements:</p>
      <div>
        Minimum age: 21 with 1 year driving experience; 25 years with one year driving experience for luxury
        cars. <br>
        Young driver supplement are different by each rental company. There is no maximum age as long as
        you have a valid driving license.
      </div>

      <p class="heading-2">International Driving Licence:</p>
      <div>
        Please note that if you do not have an EU driving licence you will need to bring an international driving
        licence along with your original licence to rent the car. <br>
        Driver's license printed with non Roman Alphabet (Arabic, Chinese, Japanese, Cyrillic etc) must be
        complemented by an international driver's license. For driver's licenses from countries not part of the
        international driver's license treaty, an official translation of the license must be presented with the
        original license.
      </div>

      <p class="heading-2">Additional Driver:</p>
      <div>
        Additional driver conditions are determined by each rental company.
      </div>

      <p class="heading-2">Payment:</p>
      <div>
        A major credit card (Visa or Mastercard) in the name of the main driver is required in order to collect
        the car. (Please note that cards without the driver's name on will not be accepted). This company will
        not accept prepaid, recharge or virtual credit cards. <br>
        Client will pay only a booking fee to CAR-BRO.com and other amounts will be paid in the desk after
        arrival.
      </div>

      <p class="heading-2">Insurance:</p>
      <div>
        Client need to sign the Insurance contract on the desk, after arrival.
      </div>

      <p class="heading-2">Mileage:</p>
      <div>
        Unlimited mileage, but conditions are determined by each rental company.
      </div>

      <p class="heading-2">Fuel Policy:</p>
      <div>
        Fuel is not included in our quoted rates. <br>
        All vehicles are delivered with fuel in the tank and should be returned with the same amount of fuel.
      </div>

      <p class="heading-2">Other:</p>
      <div>
        Please note that the supplier for this booking allows up to 1 hour of courtesy from the time on your
        rental voucher. If you do not collect your car or inform us or the supplier of a delay before this time,
        your booking will be cancelled by the supplier. <br>
        Please note that you must present the printed rental voucher on collection of the car. <br>
        Please note that in case of accident or damage to the car you must always contact the supplier
        straight away. You will need to complete a damage or accident report form for the insurance,
        otherwise you may not be covered.
      </div>

      <p class="heading-2">Cancellation policy:</p>
      <div>
        To obtain a refund of a prepaid rental you must cancel it before the start of your rental using the links
        contained in the voucher. The booking fee is not included in the return.
      </div>

      <p class="heading-2">Cross Border Travel</p>
      <div>
        For international cross border restrictions, please check with the car rental company before arrival.
        Please note that cross border charges may apply and that not all suppliers permit customers to take
        the vehicles across the border.
      </div>

      <p class="heading-2">Renter’s Responsibility</p>
      <div>
        All rentals are charged on a 24 hour basis. A full day is still charged for any portion of a 24 hour period
        used. The renter is responsible for all tolls, congestion charges, parking & traffic fines.
      </div>

      <p class="heading-2">Protection of Personal Data</p>
      <div>
        For the purposes of the provisions of currently-applicable legislation on the protection of personal data
        and information society services and electronic commerce, CAR-BRO informs you that your personal
        data – both data provided for the formalization of this contract and data compiled during the provision
        of rental services – are to be included in a personal data file.
      </div>

    </div>
  </div>

  <div class="actions">
    <button nbButton size="small" (click)="print()">Print</button>
    <button nbButton size="small" (click)="close()">Cancel</button>
  </div>

</div>
