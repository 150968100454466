import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NbIconModule } from '@nebular/theme';

@Component({
  selector: 'car-dialog-header',
  template: `
    <div class="dialog-header">

      <div class="dialog-title">
        {{ title }}
      </div>

      <nb-icon (click)="close.emit()" icon="close-outline" pack="eva"></nb-icon>

    </div>
  `,
  styles: [
    `.dialog-header {
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--color-primary-blue);
      font-weight: 700;
      text-transform: uppercase;

      nb-icon {
        color: var(--color-primary-blue);
        cursor: pointer;
        font-size: 1.5rem;
      }
    }`,
  ],
  standalone: true,
  imports: [
    CommonModule,
    NbIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Input() title = '';
  @Output() close = new EventEmitter()
}
