import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[carAgeFormat]',
  standalone: true
})
export class AgeFormatDirective implements OnInit {
  @HostListener('keydown.backspace', ['$event']) backspaceDetect(): void {
    if (this.model.control?.value >= 35 && this.model.control?.value <= 65) {
      this.model.valueAccessor?.writeValue('');
      this.model.control?.setValue('');
    }
  }

  constructor(private model: NgControl) {}

  ngOnInit(): void {
    this._updateValue(this.model.control?.value);
    this.model.valueChanges?.pipe(untilDestroyed(this)).subscribe(this._updateValue.bind(this));
  }

  private _updateValue(val: number): void {
    this.model.valueAccessor?.writeValue(val >= 35 && val <= 65 ? '35-65' : val);
  }

}
