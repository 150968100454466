<car-head-block>
  <div class="booking-header">

    <div class="form" [formGroup]="form">

      <div class="form-group">
        <p class="title required">Car pick up location?</p>
        <nb-form-field class="form-field">
          <nb-icon nbPrefix icon="pin-outline" pack="eva"></nb-icon>
          <input
            formControlName="pickUpLocation"
            placeholder="Rio International Airport (GIG)"
            fullWidth
            shape="round"
            size="small"
            nbInput
            [nbAutocomplete]="pickUpAutocomplete"
          >
          <nb-autocomplete [handleDisplayFn]="handeDeskView" #pickUpAutocomplete>
            <nb-option *ngFor="let desk of (filteredPickUp$ | async)" [value]="desk">
              {{ handeDeskView(desk) }}
            </nb-option>
          </nb-autocomplete>
        </nb-form-field>
        <car-error-message [control]="form.controls['pickUpLocation']"></car-error-message>
      </div>

      <div class="form-group">
        <nb-checkbox
          [(ngModel)]="returnToSameLocation"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="toggleCheckboxWithValidators($event, 'dropOffLocation')"
        >Return car to same location?
        </nb-checkbox>
      </div>

      <ng-container *ngIf="!returnToSameLocation">
        <div class="form-group">
          <p class="title required">Car drop off location?</p>
          <nb-form-field class="form-field">
            <nb-icon nbPrefix icon="pin-outline" pack="eva"></nb-icon>
            <input
              formControlName="dropOffLocation"
              placeholder="Rio International Airport (GIG)"
              fullWidth
              shape="round"
              size="small"
              nbInput
              [nbAutocomplete]="pickUpAutocomplete"
            >
            <nb-autocomplete [handleDisplayFn]="handeDeskView" #pickUpAutocomplete>
              <nb-option *ngFor="let desk of (filteredDropOff$ | async)" [value]="desk">
                {{ handeDeskView(desk) }}
              </nb-option>
            </nb-autocomplete>
          </nb-form-field>
          <car-error-message [control]="form.controls['dropOffLocation']"></car-error-message>
        </div>
      </ng-container>

      <div class="date-time">
        <div class="form-group short">
          <p class="title required">Pick up date</p>
          <nb-form-field>
            <nb-icon nbPrefix icon="calendar-outline" pack="eva"></nb-icon>
            <input
              formControlName="pickUpDate"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Date"
              [nbDatepicker]="pickUpDate"
            >
            <nb-datepicker
              [max]="form.controls['dropOffDate'].value"
              [filter]="datesAfterToday"
              (blur)="setControlTouched(form.controls['pickUpDate'])"
              #pickUpDate
            ></nb-datepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['pickUpDate']"></car-error-message>
        </div>

        <div class="form-group">
          <p class="title required">Pick up time</p>
          <nb-form-field>
            <nb-icon nbPrefix icon="clock-outline" pack="eva"></nb-icon>
            <input
              formControlName="pickUpTime"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Time"
              [nbTimepicker]="pickUpTime"
            >
            <nb-timepicker
              [singleColumn]="true"
              [step]="15"
              [showFooter]="false"
              (blur)="setControlTouched(form.controls['pickUpTime'])"
              (onSelectTime)="$event.save ? '' : pickUpTime.saveValue()"
              #pickUpTime
            ></nb-timepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['pickUpTime']"></car-error-message>
        </div>
      </div>

      <div class="date-time">
        <div class="form-group short">
          <p class="title required">Drop off date</p>
          <nb-form-field>
            <nb-icon nbPrefix icon="calendar-outline" pack="eva"></nb-icon>
            <input
              formControlName="dropOffDate"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Date"
              [nbDatepicker]="dropOffDate"
            >
            <nb-datepicker
              [min]="form.controls['pickUpDate'].value"
              (blur)="setControlTouched(form.controls['dropOffDate'])"
              #dropOffDate
            ></nb-datepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['dropOffDate']"></car-error-message>
        </div>

        <div class="form-group">
          <p class="title required">Drop off time</p>
          <nb-form-field>
            <nb-icon nbPrefix icon="clock-outline" pack="eva"></nb-icon>
            <input
              formControlName="dropOffTime"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Time"
              [nbTimepicker]="dropOffTime"
            >
            <nb-timepicker
              [singleColumn]="true"
              [step]="15"
              [showFooter]="false"
              (blur)="setControlTouched(form.controls['dropOffTime'])"
              (onSelectTime)="$event.save ? '' : dropOffTime.saveValue()"
              #dropOffTime
            ></nb-timepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['dropOffTime']"></car-error-message>
        </div>
      </div>

      <div class="form-group">
        <div class="date-error" *ngIf="datesError">
          <nb-icon icon="alert-circle-outline" pack="eva" status="danger"></nb-icon>
          The drop off date must be greater than the pick up date by at least 1 hour!
        </div>
        <div class="date-error" *ngIf="inPastError">
          <nb-icon icon="alert-circle-outline" pack="eva" status="danger"></nb-icon>
          The pick up date can't be in the past!
        </div>
      </div>

      <div class="form-group">
        <nb-checkbox
          [(ngModel)]="defaultAge"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="toggleCheckboxWithValidators($event, 'driversAge')"
        >
          Driver aged 35-65 years?
        </nb-checkbox>
      </div>

      <ng-container *ngIf="!defaultAge">
        <div class="form-group short">
          <p class="title required">Drivers age?</p>
          <nb-form-field class="form-field">
            <nb-icon nbPrefix icon="person-outline" pack="eva"></nb-icon>
            <input
              nbInput
              formControlName="driversAge"
              shape="round"
              size="medium"
              placeholder="21"
              [mask]="ageMask"
              carAgeFormat
            >
          </nb-form-field>
          <car-error-message [control]="form.controls['driversAge']"></car-error-message>
        </div>
      </ng-container>

      <button
        [disabled]="form.invalid || datesError || inPastError"
        (click)="search()"
        nbButton
        fullWidth
        shape="round"
      > Search </button>
    </div>

    <div class="heading">
      <p class="paragraph">We book,</p>
      <p class="paragraph bottom">You drive!</p>
    </div>

  </div>
</car-head-block>
