import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbButtonModule } from '@nebular/theme';
import { map } from 'rxjs';
import { FleetInterface } from 'src/app/core/interfaces/fleet.interface';
import { CarCalculationsService } from 'src/app/core/services/car-calculations.service';
import { ImgPlaceholderDirective } from '../../../core/directives/img-placeholder.directive';
import { FleetsDataService } from '../../../core/services/fleets-data.service';
import { RatesDataService } from '../../../core/services/rates-data.service';
import { SearchResultsDataService } from '../../../core/services/search-results-data.service';
import { getFleetLogoUrlByIdAndRateGroup } from '../../../core/helpers';
import { AvailableVehicleInterface, EquipmentInterface } from '../../../core/interfaces/rates.interfaces';
import { IconListComponent } from '../icon-list/icon-list.component';

@Component({
  selector: 'car-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  providers: [CarCalculationsService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NbButtonModule,
    IconListComponent,
    ImgPlaceholderDirective,
  ],
})
export class InfoCardComponent implements OnInit {
  @Input() car!: AvailableVehicleInterface;

  bookingDays$ = this.ratesDataService.bookingDays$;
  fleetLogoUrl$ = this.fleetsDataService.fleets$.pipe(
    map((fleets: FleetInterface[]) => getFleetLogoUrlByIdAndRateGroup(this.car.vehicle.fleet_id, (this.car.rental_rate.vehicle_charges.find((e) => e.ota_code === 1)?.description ?? ''), fleets)),
  );

  constructor(
    private router: Router,
    private searchResultDataService: SearchResultsDataService,
    private fleetsDataService: FleetsDataService,
    private ratesDataService: RatesDataService,
    public carCalculationsService: CarCalculationsService,
  ) {}

  ngOnInit(): void {
    this.carCalculationsService.selectedCar = this.car;
  }

  bookCar(): void {
    this.searchResultDataService.saveSelectedCar(this.car);
    this.router.navigateByUrl('/booking/confirmation');
  }

  get includedInPrice(): string[] {
    return this.car.equipment
      .filter((item: EquipmentInterface) => item.charge.included_in_rate !== 'false')
      .map((item: EquipmentInterface) => item.description);
  }
}
