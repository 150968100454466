import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbIconLibraries, NbLayoutModule } from '@nebular/theme';
import { CarBroIcons } from '../assets/icons';



@Component({
  selector: 'app-root',
  template: `
    <nb-layout>
      <nb-layout-column>
        <router-outlet></router-outlet>
      </nb-layout-column>
    </nb-layout>
  `,
  imports: [NbLayoutModule, RouterOutlet, NbEvaIconsModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerSvgPack('car-bro', CarBroIcons);
  }
}
