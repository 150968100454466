import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'car-head-block',
  templateUrl: './head-block.component.html',
  styleUrls: ['./head-block.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadBlockComponent {
  isHomePage = this.router.url === '/';

  constructor(private router: Router) {}
}
