import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
} from '@nebular/theme';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { TokenInterceptor } from './app/core/interceptors/token.interceptor';
import { APP_ROUTES } from './app/app-routes';
import { AppComponent } from './app/app.component';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  bootstrapApplication(AppComponent, {
    providers: [
      provideRouter(APP_ROUTES),
      provideEnvironmentNgxMask({ validation: false }),

      importProvidersFrom(HttpClientModule),
      importProvidersFrom(BrowserAnimationsModule),
      importProvidersFrom(NbDatepickerModule.forRoot()),
      importProvidersFrom(NbTimepickerModule.forRoot()),
      importProvidersFrom(NbThemeModule.forRoot({ name: 'default' })),
      importProvidersFrom(NbDateFnsDateModule.forRoot({ format: 'dd.MM.yyyy' })),
      importProvidersFrom(NbDialogModule.forRoot({ closeOnBackdropClick: true, closeOnEsc: true })),
      importProvidersFrom(NbToastrModule.forRoot()),

      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ],
  }).catch(err => console.error(err));
}


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
