import {CommonModule} from '@angular/common';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild,} from '@angular/core';
import {AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, ValidatorFn, Validators,} from '@angular/forms';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {addDays, isAfter, sub} from 'date-fns';
import {NgxMaskDirective} from 'ngx-mask';
import {filter, map, Observable, switchMap} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {datesAfterToday, fuzzySearch, getAgeMask, handeDeskView, isWrongInterval, mergeDateAndTime} from 'src/app/core/helpers';
import {DeskInterface} from 'src/app/core/interfaces/desks.interfaces';
import {SearchFormInterface} from 'src/app/core/interfaces/search-form.interfaces';
import {searchFormToDTO} from 'src/app/core/mappers/rates.mappers';
import {AgeFormatDirective} from '../core/directives/age-format.directive';
import {DesksDataService} from '../core/services/desks-data.service';
import {RatesDataService} from '../core/services/rates-data.service';
import {SearchResultsDataService} from '../core/services/search-results-data.service';
import {ErrorMessageComponent} from '../core/components/error-message/error-message.component';
import {HeadBlockComponent} from '../layout/head-block/head-block.component';
import {INIT_DATE_TIME} from '../constants';
import {NbAutocompleteModule, NbButtonModule, NbCheckboxModule, NbDatepickerComponent, NbDatepickerModule, NbFormFieldModule, NbIconModule, NbInputModule, NbTimepickerModule,} from '@nebular/theme';

export const DEFAULT_AGE = 35;

@UntilDestroy()
@Component({
  selector: 'car-home-body-header',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ErrorMessageComponent,
    NbButtonModule,
    NbIconModule,
    NbFormFieldModule,
    NbCheckboxModule,
    NbTimepickerModule,
    NbDatepickerModule,
    NbInputModule,
    NbAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskDirective,
    AgeFormatDirective,
    HeadBlockComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('dropOffDate') dropOffDate!: NbDatepickerComponent<Date>;

  datesAfterToday = datesAfterToday;

  returnToSameLocation = true;
  defaultAge = true;

  dynamicValidators: { [key: string]: ValidatorFn[] } = {
    driversAge: [Validators.required, Validators.min(18), Validators.max(99)],
    dropOffLocation: [Validators.required]
  };

  form = this.fb.group({
    pickUpLocation: new UntypedFormControl(null, Validators.required),
    pickUpDate: new UntypedFormControl(INIT_DATE_TIME.pickUpDate, Validators.required),
    pickUpTime: new UntypedFormControl(INIT_DATE_TIME.pickUpTime, Validators.required),
    dropOffLocation:  new UntypedFormControl(null),
    dropOffDate: new UntypedFormControl(null, Validators.required),
    dropOffTime: new UntypedFormControl(INIT_DATE_TIME.dropOffTime, Validators.required),
    driversAge: new UntypedFormControl(null)
  });

  desks$ = this.desksDataService.desks$;
  filteredDropOff$!: Observable<DeskInterface[]>;
  filteredPickUp$!: Observable<DeskInterface[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private searchResultDataService: SearchResultsDataService,
    private desksDataService: DesksDataService,
    private ratesDataService: RatesDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.desksDataService.loadDesks();
  }

  ngOnInit(): void {
    this.form.controls['pickUpDate'].valueChanges
      .pipe(
        filter(Boolean),
        untilDestroyed(this)
      )
      .subscribe((value: Date) => {
        this.form.controls['dropOffDate'].setValue(addDays(value, 3));
      });
    this.filteredPickUp$ = this.getFilteredDesks(this.form.controls['pickUpLocation']);
    this.filteredDropOff$ = this.getFilteredDesks(this.form.controls['dropOffLocation']);
  }

  ngAfterViewInit(): void {
    // for correct min date before select date in pickUpDate
    this.dropOffDate.min = sub(INIT_DATE_TIME.pickUpDate, { days: 1 });
    this.dropOffDate.visibleDate = sub(INIT_DATE_TIME.pickUpDate, { days: 1 });
    this.cdr.detectChanges();
  }

  handeDeskView = handeDeskView;

  get datesError() {
    const { pickUpDate, pickUpTime, dropOffDate, dropOffTime } = this.form.getRawValue();
    return isWrongInterval(pickUpDate, pickUpTime, dropOffDate, dropOffTime);
  }

  get inPastError(): boolean {
    const pickUpDate = this.form.controls['pickUpDate'];
    const pickUpTime = this.form.controls['pickUpTime'];

    if (this.form.untouched) return false;

    const date = mergeDateAndTime(new Date(pickUpDate.value || ''), new Date(pickUpTime.value || ''));
    return isAfter(new Date(), date);
  }

  get ageMask(): string {
    return getAgeMask(this.form.controls['driversAge']?.value);
  }

  toggleCheckboxWithValidators(value: boolean, controlName: string): void {
    const control = this.form.get(controlName);
    if (value) {
      control?.clearValidators();
    } else {
      control?.setValidators(this.dynamicValidators[controlName]);
    }
    control?.reset();
  }

  setControlTouched(control: AbstractControl): void {
    control.markAsTouched();
  }

  search(): void {
    const formValues = this.form.getRawValue();
    const form = {
      ...formValues,
      dropOffLocation: this.returnToSameLocation ? formValues.pickUpLocation : formValues.dropOffLocation,
      driversAge: this.defaultAge ? DEFAULT_AGE : formValues.driversAge
    } as SearchFormInterface;

    this.searchResultDataService.saveSearchForm(form);

    this.ratesDataService.loadRates(searchFormToDTO(form));
    this.router.navigateByUrl('/booking');
  }

  private getFilteredDesks(control: AbstractControl): Observable<DeskInterface[]> {
    return control.valueChanges.pipe(
      startWith(''),
      switchMap((value: string) =>
        this.desks$.pipe(
          map((desks: DeskInterface[]) => desks.filter((desk: DeskInterface) => Boolean(desk.active))),
          map((desks: DeskInterface[]) => fuzzySearch(desks, ['name', 'city', 'state'], value || '')),
        )
      ),
      untilDestroyed(this)
    );
  }
}
