import { Routes } from '@angular/router';
import { BookingConfirmationGuard } from './booking/booking-confirmation/booking-confirmation.guard';
import { BookingVoucherGuard } from './booking/booking-voucher/booking-voucher.guard';
import { BookingConfirmationComponent } from './booking/booking-confirmation/booking-confirmation.component';
import { BookingSearchListComponent } from './booking/booking-search-list/booking-search-list.component';
import { BookingVoucherComponent } from './booking/booking-voucher/booking-voucher.component';
import { BookingComponent } from './booking/booking.component';
import { HomeComponent } from './home/home.component';

export const APP_ROUTES: Routes = [
  {
    path: 'booking',
    component: BookingComponent,
    children: [
      {
        path: '',
        component: BookingSearchListComponent,
        data: { title: 'Car-Bro - Available cars' }
      },
      {
        path: 'confirmation',
        component: BookingConfirmationComponent,
        data: { title: 'Car-Bro - Confirmation' },
        canActivate: [BookingConfirmationGuard]
      },
      {
        path: 'voucher',
        component: BookingVoucherComponent,
        data: { title: 'Car-Bro - Voucher' },
        canActivate: [BookingVoucherGuard]
      }
    ]
  },
  { path: '', component: HomeComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];
