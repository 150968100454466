import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NbIconModule } from '@nebular/theme';
import { EMAIL_PATTERN } from '../../validators';

export const ERROR_TEXT = {
  required: 'This field is required',
  minLength: 'Min length of this field is ',
  maxLength: 'Max length of this field is ',
  min: 'The min value of this field is ',
  max: 'The max value of this field is ',
  email: 'Enter valid email address',
  expDate: 'Enter valid expiration date',
  ccNumber: 'Enter valid credit card number',
  onlyLetters: 'This field must consist only letters'
};

@Component({
  selector: 'car-error-message',
  standalone: true,
  template: `
    <div class="validation-error" *ngIf="control?.invalid && control?.touched">
      <nb-icon icon="alert-circle-outline" pack="eva" status="danger"></nb-icon>
      {{ errorText }}
    </div>
  `,
  imports: [
    CommonModule,
    NbIconModule,
  ],
  styles: [`
    .validation-error {
      align-items: center;
      color: var(--color-danger-500);
      display: flex;
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1rem;

      nb-icon {
        font-size: 1rem;
        margin-right: 0.375rem;
      }
    }
  `],
})
export class ErrorMessageComponent {
  @Input() control?: AbstractControl;
  errorMessages = ERROR_TEXT;

  get errorText(): string {
    if (!this.control || !this.control.errors) return '';
    let errors = this.control.errors;

    if (errors['required']) return this.errorMessages.required;
    if (errors['email']) return this.errorMessages.email;
    if (errors['minlength']) return `${this.errorMessages.minLength} ${errors['minlength'].requiredLength}`;
    if (errors['maxlength']) return `${this.errorMessages.maxLength} ${errors['maxlength'].requiredLength}`;
    if (errors['min']) return `${this.errorMessages.min} ${errors['min'].min}`;
    if (errors['max']) return `${this.errorMessages.max} ${errors['max'].max}`;
    if (errors['expDate']) return this.errorMessages.expDate;
    if (errors['ccNumber']) return this.errorMessages.ccNumber;
    if (errors['onlyLetters']) return this.errorMessages.onlyLetters

    if (errors['pattern']) {
      switch (errors['pattern'].requiredPattern) {
        case EMAIL_PATTERN: return this.errorMessages.email
      }
    }

    return '';
  }
}
