<div class="icons" *ngIf="car" [ngStyle]="{ 'justify-content': alignCenter ? 'center' : 'flex-start' }">
  <nb-icon *ngIf="withAirConditioner()" icon="conditioner" pack="car-bro"></nb-icon>

  <div class="with-text">
    <nb-icon icon="transmission" pack="car-bro"></nb-icon>
    <div class="text">{{ car.vehicle.transmission_type[0] }}</div>
  </div>


  <div class="with-text">
    <nb-icon icon="awd" pack="car-bro"></nb-icon>
    <div class="text">{{ isAWD() ? 4 : 2 }}</div>
  </div>

  <div *ngIf="car.vehicle.baggage_quantity" class="with-text">
    <nb-icon icon="baggage" pack="car-bro"></nb-icon>
    <div class="text">{{ car.vehicle.baggage_quantity }}</div>
  </div>

  <div class="with-text">
    <nb-icon icon="seat" pack="car-bro"></nb-icon>
    <div class="text">{{ car.vehicle.passenger_quantity }}</div>
  </div>

  <div class="with-text">
    <nb-icon icon="door" pack="car-bro"></nb-icon>
    <div class="text">{{ car.vehicle.door_count }}</div>
  </div>

<!--  <nb-icon icon="child-seat" pack="car-bro"></nb-icon>-->
</div>
