<car-head-block>
  <div class="booking-header">

    <div class="stepper">
      <nb-stepper #stepper (stepChange)="stepChanged($event)" [selectedIndex]="currentStep">
        <nb-step label="Search results"></nb-step>
        <nb-step label="Booking details"></nb-step>
        <nb-step label="Voucher"></nb-step>
      </nb-stepper>
    </div>

    <div class="form" [formGroup]="form" *ngIf="isSearchList">

      <div class="form-line">
        <div class="form-group">
          <p class="title required">Car pick up location?</p>
          <nb-form-field class="form-field">
            <input
              formControlName="pickUpLocation"
              placeholder="Rio International Airport (GIG)"
              fullWidth
              shape="round"
              size="small"
              nbInput
              [nbAutocomplete]="pickUpAutocomplete"
            >
            <nb-autocomplete [handleDisplayFn]="handeDeskView" #pickUpAutocomplete>
              <nb-option *ngFor="let desk of (filteredPickUp$ | async)" [value]="desk">
                {{ handeDeskView(desk) }}
              </nb-option>
            </nb-autocomplete>
          </nb-form-field>
          <car-error-message [control]="form.controls['pickUpLocation']"></car-error-message>
        </div>

        <div class="form-group">
          <p class="title required">Pick up date</p>
          <nb-form-field>
            <input
              fullWidth
              formControlName="pickUpDate"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Date"
              [nbDatepicker]="pickUpDate"
            >
            <nb-datepicker
              [max]="form.controls['dropOffDate'].value"
              [filter]="datesAfterToday"
              (blur)="setControlTouched(form.controls['pickUpDate'])"
              #pickUpDate
            ></nb-datepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['pickUpDate']"></car-error-message>
        </div>

        <div class="form-group">
          <p class="title required">Pick up time</p>
          <nb-form-field>
            <input
              fullWidth
              formControlName="pickUpTime"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Time"
              [nbTimepicker]="pickUpTime"
            >
            <nb-timepicker
              [singleColumn]="true"
              [step]="15"
              [showFooter]="false"
              (blur)="setControlTouched(form.controls['pickUpTime'])"
              (onSelectTime)="$event.save ? '' : pickUpTime.saveValue()"
              #pickUpTime
            ></nb-timepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['pickUpTime']"></car-error-message>
        </div>

        <div class="form-group">
          <p class="title required">Drivers age</p>
          <nb-form-field class="form-field">
            <input
              nbInput
              formControlName="driversAge"
              shape="round"
              size="medium"
              placeholder="21"
              carAgeFormat
              [specialCharacters]="['-']"
              [mask]="ageMask"
            >
          </nb-form-field>
          <car-error-message [control]="form.controls['driversAge']"></car-error-message>
        </div>
      </div>

      <div class="form-line">
        <div class="form-group">
          <p class="title required">Car drop off location?</p>
          <nb-form-field class="form-field">
            <input
              formControlName="dropOffLocation"
              placeholder="Rio International Airport (GIG)"
              fullWidth
              shape="round"
              size="small"
              nbInput
              [nbAutocomplete]="dropOffAutocomplete"
            >
            <nb-autocomplete [handleDisplayFn]="handeDeskView" #dropOffAutocomplete>
              <nb-option *ngFor="let desk of (filteredDropOff$ | async)" [value]="desk">
                {{ handeDeskView(desk) }}
              </nb-option>
            </nb-autocomplete>
          </nb-form-field>
          <car-error-message [control]="form.controls['dropOffLocation']"></car-error-message>
        </div>

        <div class="form-group">
          <p class="title required">Drop off date</p>
          <nb-form-field>
            <input
              fullWidth
              formControlName="dropOffDate"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Date"
              [nbDatepicker]="dropOffDate"
            >
            <nb-datepicker
              [min]="form.controls['pickUpDate'].value"
              [filter]="datesAfterToday"
              (blur)="setControlTouched(form.controls['dropOffDate'])"
              #dropOffDate
            ></nb-datepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['dropOffDate']"></car-error-message>
        </div>

        <div class="form-group">
          <p class="title required">Drop off time</p>
          <nb-form-field>
            <input
              fullWidth
              formControlName="dropOffTime"
              shape="round"
              size="small"
              nbInput
              placeholder="Pick Time"
              [nbTimepicker]="dropOffTime"
            >
            <nb-timepicker
              [singleColumn]="true"
              [step]="15"
              [showFooter]="false"
              (blur)="setControlTouched(form.controls['dropOffTime'])"
              (onSelectTime)="$event.save ? '' : dropOffTime.saveValue()"
              #dropOffTime
            ></nb-timepicker>
          </nb-form-field>
          <car-error-message [control]="form.controls['dropOffTime']"></car-error-message>
        </div>

        <div class="form-group button">
          <button
            [disabled]="form.invalid || datesIntervalError || inPastError || (ratesIsLoading$ | async)"
            fullWidth
            nbButton
            shape="round"
            (click)="search()"
          >Search
          </button>
        </div>

      </div>

      <div class="date-error" *ngIf="datesIntervalError">
        <nb-icon icon="alert-circle-outline" pack="eva" status="danger"></nb-icon>
        The drop off date must be greater than the pick up date by at least 1 hour!
      </div>
      <div class="date-error" *ngIf="inPastError">
        <nb-icon icon="alert-circle-outline" pack="eva" status="danger"></nb-icon>
        The pick up date can't be in the past!
      </div>
    </div>

  </div>
</car-head-block>

<div class="wrapper">
  <router-outlet></router-outlet>
</div>
