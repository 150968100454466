import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { NbButtonModule, NbDialogRef } from '@nebular/theme';
import { PdfCreatorService } from 'src/app/core/services/pdf-creator.service';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
  selector: 'car-terms-conditions-dialog',
  templateUrl: './terms-conditions-dialog.component.html',
  styleUrls: ['./terms-conditions-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DialogHeaderComponent,
    NbButtonModule,
  ],
})
export class TermsConditionsDialogComponent {
  @ViewChild('terms') terms!: ElementRef<HTMLElement>;


  constructor(
    protected dialogRef: NbDialogRef<TermsConditionsDialogComponent>,
    private pdfCreatorService: PdfCreatorService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  print(): void {
    this.pdfCreatorService.printTermsAndConditions(this.terms.nativeElement);
  }

}
