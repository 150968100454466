import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, Observable } from 'rxjs';
import { FleetsApiService } from '../api/fleets.api.service';
import { FleetInterface } from '../interfaces/fleet.interface';

@Injectable({
  providedIn: 'root'
})
export class FleetsDataService {
  private readonly _fleets$ = new BehaviorSubject<FleetInterface[]>([]);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private fleetsApiService: FleetsApiService) {}

  public get fleets$(): Observable<FleetInterface[]> {
    return this._fleets$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  public loadFleets(): void {
    this._isLoading$.next(true);

    this.fleetsApiService.getFleets().pipe(
      finalize(() => this._isLoading$.next(false)),
      catchError(() => {
        this._fleets$.next([]);
        return [];
      })
    )
      .subscribe((fleets: FleetInterface[]) => this._fleets$.next(fleets));
  }
}
