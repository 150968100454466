export interface RequestInterface {
  pickup_datetime: string;
  return_datetime: string;
  pickup_desk_code: string;
  return_desk_code: string;
  rental_days: number;
}

export interface AvailableVehicleInterface {
  status: string; // should be enum
  vehicle: VehicleInterface;
  rental_rate: RentalRateInterface;
  equipment: EquipmentInterface[];
  total_charge: TotalChargeInterface;
  coverages: PricedCoverage[];
  quotation_id: string;
  rate_adjustment: number;
}

export interface VehicleInterface {
  passenger_quantity: number;
  baggage_quantity: string;
  code: string;
  code_context: string;
  door_count: number;
  make_model: string;
  transmission_type: TransmissionType;
  drive_type: string; // should be enum
  image: string;
  fleet_id: number;
}

export interface RentalRateInterface {
  rate_distance: RateDistanceInterface;
  vehicle_charges: VehicleChargeInterface[];
}

export interface RateDistanceInterface {
  unlimited: string;
}

export interface VehicleChargeInterface {
  amount: string;
  description: string;
  ota_code: number;
  tax_inclusive: string;
  tax_amount: TaxAmountInterface;
}

export interface TaxAmountInterface {
  total: number;
  percentage: number;
  description: string;
}

export interface EquipmentInterface {
  ota_code: number;
  description: string;
  charge: ChargeInterface;
  quantity: number;
}

export interface ChargeInterface {
  amount: string;
  tax_inclusive: string;
  included_in_rate: string;
  included_in_est_total_ind: string;
  tax_amount: TaxAmountInterface;
}

export interface TotalChargeInterface {
  rate_total_amount: number;
  estimated_total_amount: number;
}

export interface PricedCoverage {
  charge: ChargeInterface;
  coverage: CoverageInterface;
  deductible: DeductibleInterface;
  required: string;
}

export interface CoverageInterface {
  coverage_type: number;
  coverage_text_type: string;
  value: string;
}

export interface DeductibleInterface {
  excess_amount: number;
}

export interface RatesInterface {
  request: RequestInterface;
  available_vehicles: AvailableVehicleInterface[];
}

export interface ErrorInterface {
  code: number;
  short_text: string;
  type: string;
}

export enum TransmissionType {
  Manual = 'Manual',
  Automatic = 'Automatic'
}

export interface ErrorResponseInterface {
  errors: ErrorInterface[] | ErrorInterface;
}
