import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AvailableVehicleInterface } from '../interfaces/rates.interfaces';
import { SearchFormInterface } from '../interfaces/search-form.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SearchResultsDataService {
  private readonly _searchForm$ = new BehaviorSubject<SearchFormInterface | null>(null);

  private readonly _selectedCar$ = new BehaviorSubject<AvailableVehicleInterface | null>(null);

  public get searchForm$(): Observable<SearchFormInterface | null> {
    return this._searchForm$.asObservable();
  }

  public get selectedCar$(): Observable<AvailableVehicleInterface | null> {
    return this._selectedCar$.asObservable();
  }

  public get isCarSelected$(): Observable<boolean> {
    return this._selectedCar$.asObservable().pipe(map(Boolean));
  }

  public saveSearchForm(form: SearchFormInterface): void {
    this._searchForm$.next(form);
  }

  public clearSearchForm(): void {
    this._searchForm$.next(null);
  }

  public saveSelectedCar(car: AvailableVehicleInterface): void {
    this._selectedCar$.next(car);
  }

  public clearSelectedCar(): void {
    this._selectedCar$.next(null);
  }
}
