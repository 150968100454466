import { Injectable } from '@angular/core';

import { NbIconConfig, NbToastrConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastrService: NbToastrService) {}

  options: Partial<NbToastrConfig> = {
    duration: 5000,
    preventDuplicates: true
  }

  success(
    message: string = 'OK!',
    title: string = 'Success',
  ): void {
    this.toastrService.success(message, title, this.options);
  }

  warning(
    message: string = 'Something went wrong!',
    title: string = 'Warning',
  ): void {
    this.toastrService.warning(message, title, this.options);
  }

  danger(
    message: string = 'Something went wrong!',
    title: string = 'Error',
  ): void {
    const iconConfig: NbIconConfig = { icon: 'alert-circle-outline', pack: 'eva' };
    this.toastrService.danger(message, title, { ...this.options, ...iconConfig });
  }
}
