import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, Observable } from 'rxjs';
import { DesksApiService } from '../api/desks.api.service';
import { DeskInterface } from '../interfaces/desks.interfaces';
import { Pageable } from '../interfaces/pageable.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DesksDataService {
  private readonly _desks$ = new BehaviorSubject<DeskInterface[]>([]);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private desksApiService: DesksApiService) {}

  public get desks$(): Observable<DeskInterface[]> {
    return this._desks$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  public loadDesks(): void {
    this._isLoading$.next(true);

    this.desksApiService.getDesks().pipe(
        finalize(() => this._isLoading$.next(false)),
        catchError(() => {
          this._desks$.next([]);
          return [];
        })
      )
      .subscribe((desks: Pageable<DeskInterface>) => this._desks$.next(desks.data));
  }
}
