import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReservationInterface } from 'src/app/core/interfaces/reservations.interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  constructor(private http: HttpClient) {}

  validatePaymentUrl = (id: string) => `${environment.apiUrl}/order/${id}/payment-validation/return`;

  validatePayment(id: string, payment_token: string): Observable<ReservationInterface> {
    return this.http.get<ReservationInterface>(this.validatePaymentUrl(id), {
      params: {
        payment_token,
      },
    });
  }
}
