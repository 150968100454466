<div class="contact-us">
  <car-dialog-header (close)="close()" [title]="'Contact Us'"></car-dialog-header>

  <div class="form" [formGroup]="form">

    <div class="form-group">
      <p class="title required">Name</p>
      <nb-form-field class="form-field">
        <input
          fullWidth
          nbInput
          shape="round"
          size="medium"
          placeholder="John Doe"
          formControlName="name"
        >
      </nb-form-field>
      <car-error-message [control]="form.controls['name']"></car-error-message>
    </div>

<!--    <div class="form-group">-->
<!--      <p class="title required">Email</p>-->
<!--      <nb-form-field class="form-field">-->
<!--        <input-->
<!--          fullWidth-->
<!--          nbInput-->
<!--          shape="round"-->
<!--          size="medium"-->
<!--          placeholder="example@test.com"-->
<!--          formControlName="email"-->
<!--        >-->
<!--      </nb-form-field>-->
<!--      <car-error-message [control]="form.controls['email']"></car-error-message>-->
<!--    </div>-->

    <div class="form-group">
      <p class="title required">Message</p>
      <nb-form-field class="form-field">
        <textarea
          nbInput
          fullWidth
          shape="round"
          size="medium"
          placeholder="Enter your message..."
          formControlName="message"
        ></textarea>
      </nb-form-field>
      <car-error-message [control]="form.controls['message']"></car-error-message>
    </div>

  </div>

  <div class="actions">
    <button [disabled]="form.invalid" nbButton size="small" (click)="openEmailClient()">Send</button>
    <button nbButton size="small" (click)="close()">Cancel</button>
  </div>

</div>
