import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentRedirectInterface } from 'src/app/core/interfaces/order.interface';
import { environment } from 'src/environments/environment';
import { ReservationDTO } from '../interfaces/reservations.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReservationsApiService {

  constructor(private http: HttpClient) {}

  reservationsURL = `${environment.apiUrl}/reservations`;

  makeReservation(reservation: ReservationDTO): Observable<PaymentRedirectInterface> {
    return this.http.post<PaymentRedirectInterface>(`${this.reservationsURL}/add`, reservation, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}
