import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { SearchResultsDataService } from '../../core/services/search-results-data.service';
import { ContactUsDialogComponent } from '../../dialog/contact-us-dialog/contact-us-dialog.component';

@Component({
  selector: 'car-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgOptimizedImage,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() isHomePage = false;

  constructor(
    private searchResultDataService: SearchResultsDataService,
    private dialogService: NbDialogService
  ) {}

  public openContactUs(): void {
    this.dialogService.open(ContactUsDialogComponent);
  }

  public resetForm() {
    this.searchResultDataService.clearSearchForm();
  }
}
