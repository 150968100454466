import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { setUpInvoice } from '../../booking/booking-voucher/invoice';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export interface InvoiceSettingInterface {
  currency: string;
  voucherNumber: string;
  date: string;
  name: string;
  email: string;
  bookingFee: number;
  vat: number;
}

@Injectable({
  providedIn: 'root',
})
export class PdfCreatorService {

  constructor(
    private toastService: ToastService,
  ) {}

  downloadInvoice(invoiceData: InvoiceSettingInterface): void {
    pdfMake.createPdf(setUpInvoice(invoiceData)).download(`Invoice-${invoiceData.voucherNumber}.pdf`);
    this.toastService.success('Invoice downloaded!');
  }

  saveVoucher(voucher: HTMLElement, print: boolean): void {
    html2canvas(voucher, { backgroundColor: 'white' }).then((canvas) => {
      const pdf = new jsPDF('p', 'px', [canvas.width, canvas.height + 100]);
      const imgData = canvas.toDataURL('image/jpeg', 1.0);

      pdf.addImage(imgData, 'JPEG', 0, 50, canvas.width, canvas.height);

      if (print) {
        pdf.autoPrint();
        pdf.output('pdfobjectnewwindow');
      } else {
        pdf.save('car-bro-voucher.pdf');
        this.toastService.success('Voucher downloaded!');
      }
    });
  }

  printTermsAndConditions(terms: HTMLElement): void {
    html2canvas(terms,
      {
        backgroundColor: 'white',
        scrollY: 0,
        scrollX: 0,
        width: terms.clientWidth,
        height: terms.clientHeight,
      }).then((canvas) => {
      const pdf = new jsPDF('p', 'cm', 'a4');
      const imgData = canvas.toDataURL('image/jpeg', 1.0);

      pdf.addImage(imgData, 'JPEG', 0, 0, 21.0, 29.7);

      pdf.autoPrint();
      window.open(pdf.output('bloburl'), '_blank');
    });
  }
}
