import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbButtonModule, NbSpinnerModule } from '@nebular/theme';
import { filter, map, take, withLatestFrom } from 'rxjs';
import { InvoiceSettingInterface, PdfCreatorService } from 'src/app/core/services/pdf-creator.service';
import { createInvoiceSettings } from 'src/app/booking/booking-voucher/invoice';
import { CarCalculationsService } from 'src/app/core/services/car-calculations.service';
import { ReservationInterface } from 'src/app/core/interfaces/reservations.interfaces';
import { EquipmentInterface } from 'src/app/core/interfaces/rates.interfaces';
import { ToastService } from 'src/app/core/services/toast.service';
import { FleetsDataService } from '../../core/services/fleets-data.service';
import { ReservationDataService } from '../../core/services/reservation-data.service';
import { getFleetLogoUrlByIdAndRateGroup } from '../../core/helpers';
import { FleetInterface } from '../../core/interfaces/fleet.interface';

@Component({
  selector: 'car-booking-voucher',
  templateUrl: './booking-voucher.component.html',
  styleUrls: ['./booking-voucher.component.scss'],
  providers: [CarCalculationsService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NbButtonModule,
    NbSpinnerModule,
  ],
})
export class BookingVoucherComponent implements OnDestroy {
  @ViewChild('voucher') voucher!: ElementRef<HTMLElement>;

  orderId = '';
  paymentToken = '';

  reservation$ = this.reservationsDataService.reservations$.pipe(filter(Boolean));
  reservationLoading$ = this.reservationsDataService.isLoading$
  fleetLogoUrl$ = this.fleetsDataService.fleets$.pipe(
    filter((res: FleetInterface[]) => Boolean(res && res.length)),
    withLatestFrom(this.reservation$),
    map(([fleets, reservation]) => getFleetLogoUrlByIdAndRateGroup(reservation.vehicle.fleet_id, (reservation.rental_rate.vehicle_charges.find((e) => e.ota_code === 1)?.description ?? ''), fleets)),
  );

  equipmentTotal$ = this.reservation$.pipe(
    map((reservation: ReservationInterface) =>
      reservation.equipment.reduce(
        (prev: number, curr: EquipmentInterface) => prev + (+curr.charge.amount + +curr.charge.tax_amount.total) * curr.quantity,
        0) * reservation.rental_days
    )
  );

  reservationDate = new Date();

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastService,
    private fleetsDataService: FleetsDataService,
    private reservationsDataService: ReservationDataService,
    public carCalculationsService: CarCalculationsService,
    private pdfCreatorService: PdfCreatorService,
  ) {
    this.fleetsDataService.loadFleets();

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.orderId = params['order'];
      this.paymentToken = params['payment_token'];
      this.reservationsDataService.validatePayment(this.orderId, this.paymentToken);
    });

    this.reservation$
      .pipe(take(1))
      .subscribe((reservation: ReservationInterface) => this.carCalculationsService.selectedCar = reservation);
  }

  savePdf(print = false): void {
    this.pdfCreatorService.saveVoucher(this.voucher.nativeElement, print);
  }

  getEquipmentString(equipment: EquipmentInterface[]): string {
    return equipment.map(item => `${item.quantity} ${item.description}`).join(', ');
  }

  downloadInvoice(): void {
    this.reservation$
      .pipe(
        withLatestFrom(this.equipmentTotal$),
        map(([reservation, equipmentTotal]) => createInvoiceSettings(reservation, this.carCalculationsService.getTotalFee(equipmentTotal, reservation.rental_days))),
        take(1))
      .subscribe((invoiceData: InvoiceSettingInterface) => this.pdfCreatorService.downloadInvoice(invoiceData));
  }

  ngOnDestroy() {
    this.reservationsDataService.clearReservation();
  }

}
